

.login-form {
  /* position: relative;
  z-index: 1; */
  
  
  width: 500px;
  
  padding: 35px;
  text-align: center;
  margin: 0 auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  
}

@media screen and (max-width: 800px) {
  .login-form  {
    width: 100%;
    margin: 0 0;
    
  }
}
.login-form label {
    font-size: 10pt;
    font-weight: 900;
}
.login-form input {
border-radius: 3px;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 5px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.login-form button {
  
  border-radius: 3px;
  outline: 0;
  background: #6FFFB0;
  width: 100%;
  border: 0;
  padding: 8px;
  font-weight: 900;
  margin: 5px;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.login-form button:hover,.login-form button:active,.login-form button:focus {
  background: #c4ffdf;
}
.login-form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
